<template>
  <div class="search_container">
    <div class="header">
      <van-nav-bar
        title="筛选文章"
        left-arrow
        @click-left="comback"
        safe-area-inset-top
      >
      </van-nav-bar>
      <form action="/">
        <van-search
          v-model="searchVal"
          show-action
          placeholder="输入文章名"
          @search="onSearch"
          @cancel="onCancel"
        >
        </van-search>
      </form>
    </div>

    <div class="search-article-list">
      <artcle-type
        v-if="loadArticles"
        @delete-ok="deletearticle"
        :articleList="articles"
        :artType="moduleType"
        :isSearch="isSearch"
        :oneType="oneType"
        :myArticle="isMyArticle"
        :twoType="twoType"
        :searchVal="searchVal"
      ></artcle-type>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getArticleList,getManageArticleList } from "../../api/articleManagement";
import { getMyArticleList } from "../../api/huokeArticle";
import artcleType from "./type/type.vue";
export default {
  components: {
    artcleType,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      oneType: Number(route.query.oneType), //管理页
      twoType: Number(route.query.twoType),
      topType:Number(route.query.topType),
      isSearch: "isSearch",
      loadArticles: false,
      articles: {},
      moduleType: Number(route.query.artType),
      isMyArticle: Number(route.query.myArticle),
      searchParams: {},
      searchVal: route.query.searchVal ? route.query.searchVal : "",
      // artType:route.query.artType?Number(route.query.artType):null,
    });

    const comback = () => {
      if (state.moduleType === 2) {
        router.push({
          name: "articleVisitors",
          params: {
            oneType: state.isMyArticle === 1 ? 3 : "",
            twoType: state.twoType,
          },
        });
      } else {
        router.push({
          name: "articleManagement",
          params: {
            oneType: route.query.oneType,
            twoType: route.query.twoType,
          },
        });
      }
    };
    const onSearch = async () => {
      // state.searchParams.title = val;
      state.searchParams.title = state.searchVal;
      state.searchParams.size = 100;
      if (state.moduleType === 2) {
        if (state.isMyArticle === 0) {
          state.searchParams.isMaterial = 1;
          state.searchParams.index = 1;
          state.searchParams.materialType = state.topType
          let result = await getArticleList(state.searchParams);
          state.articles = result.records;
          state.loadArticles = true;
        } else {
          let res = await getMyArticleList(state.searchParams);
          state.articles = res.records;
          state.loadArticles = true;
        }
      } else {
        let result = await getManageArticleList(state.searchParams);
        state.articles = result.records;
        state.loadArticles = true;
      }
    };
    //如果搜索栏不为空
    if (state.searchVal !== "") {
      state.loadArticles = false;
      console.log("搜索栏", state.searchVal);
      onSearch();
    }
    const onCancel = () => {
      if (state.searchVal !== "") {
        state.searchVal = "";
      }
    };
    //删除文章后重新加载
    function deletearticle() {
      onSearch();
    }

    return {
      deletearticle,
      ...toRefs(state),
      props,
      onCancel,
      onSearch,
      comback,
    };
  },
};
</script>

<style lang="scss">
.search_container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }

  .search-article-list{
    flex: 1;
    overflow-x: hidden;
  }
  .van-search__action {
    color: #ff6e01;
  }
  .Is-comto-material {
    font-size: 14px;
    .comto-material {
      font-weight: 600;
      padding: 18px;
      text-align: left;
    }
    .select-comin {
      .selectBox {
        float: left;
        color: #999999;
        width: 52px;
        height: 28px;
        border: 1px solid #999999;
        line-height: 28px;
        margin-left: 18px;
        border-radius: 4px;
        box-sizing: border-box;
      }
      .bgColor {
        color: #fff;
        background-color: #289fff;
        border: none;
      }
    }
  }
}
</style>